// import packages
import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'motion/react';

// import assets
import podcast from 'url:../../../public/assets/oklo_podcast.mp3';
import cleanEnergy from '../../../public/assets/future_clean_energy.png';

export const AudioPlayer = () => {
	const [audio] = useState(new Audio(podcast)); // Create the audio instance
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [volume, setVolume] = useState(1);

	// Set up audio event listeners
	useEffect(() => {
		const handleLoadedMetadata = () => setDuration(audio.duration);
		audio.addEventListener('loadedmetadata', handleLoadedMetadata);

		// Clean up event listeners
		return () => {
			audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
		};
	}, [audio]);

	// Update current time as the audio plays
	useEffect(() => {
		const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
		const handleAudioEnd = () => setIsPlaying(false);

		audio.addEventListener('timeupdate', handleTimeUpdate);
		audio.addEventListener('ended', handleAudioEnd);

		return () => {
			audio.removeEventListener('timeupdate', handleTimeUpdate);
			audio.removeEventListener('ended', handleAudioEnd);
		};
	}, [audio]);

	// Toggle play/pause
	const togglePlayPause = () => {
		if (isPlaying) {
			audio.pause();
		} else {
			audio.play();
		}
		setTimeout(() => setIsPlaying(!isPlaying), 150);
	};

	// Handle seek
	const handleSeek = e => {
		const newTime = (e.target.value / 100) * duration;
		audio.currentTime = newTime;
		setCurrentTime(newTime);
	};

	// Handle volume change
	const handleVolumeChange = e => {
		const newVolume = e.target.value;
		audio.volume = newVolume;
		setVolume(newVolume);
	};

	// Convert time to MM:SS format
	const formatTime = time => {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);
		return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	};

	// Calculate remaining time
	const getRemainingTime = () => formatTime(duration - currentTime);

	// Ensure audio state is managed on component unmount
	useEffect(() => {
		return () => {
			audio.pause();
			audio.currentTime = 0;
		};
	}, [audio]);

	return (
		<div className="">
			<div className="mx-auto px-0 bg-gray-800 rounded-xl drop-shadow-xl">
				<div className="px-8 sm:px-8 py-8 sm:py-12">
					<div className="w-full my-0 py-0 h-full drop-shadow-xl">
						<img src={cleanEnergy} alt="analyst looking at the market" className="rounded-t-xl" />
					</div>
					<div className="text-zinc-50 sm:px-0 px-0 rounded-b-xl">
						<div className="my-0">
							<div className="sm:px-8 space-y-4 py-8 sm:py-12">
								<div className="my-0 grid grid-cols-2">
									<p className="uppercase logo font-black text-[12px] text-zinc-50 text-left">Oklo 10-Q SEC Filing</p>
									<p className="uppercase logo font-normal text-[12px] text-zinc-400 text-right">LVLUPINFINANCE</p>
								</div>

								{/* ---- */}
								<div className="grid grid-cols-8">
									<div className="">
										<button onClick={togglePlayPause} className="text-3xl">
											<AnimatePresence mode="wait">
												{isPlaying ? (
													<motion.svg
														key="pause-icon"
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="currentColor"
														initial={{ scale: 0.8, opacity: 0 }}
														animate={{ scale: 1, opacity: 1 }}
														exit={{ scale: 0.8, opacity: 0 }}
														transition={{ duration: 0.2 }}>
														<rect x="6" y="4" width="4" height="16"></rect>
														<rect x="14" y="4" width="4" height="16"></rect>
													</motion.svg>
												) : (
													<motion.svg
														key="play-icon"
														xmlns="http://www.w3.org/2000/svg"
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="currentColor"
														initial={{ scale: 0.8, opacity: 0 }}
														animate={{ scale: 1, opacity: 1 }}
														exit={{ scale: 0.8, opacity: 0 }}
														transition={{ duration: 0.2 }}>
														<polygon points="5,3 19,12 5,21"></polygon>
													</motion.svg>
												)}
											</AnimatePresence>
										</button>
									</div>
									<div className="col-span-6 pr-4 sm:pr-8">
										<input
											type="range"
											className="w-full h-2 bg-gray-600 rounded-lg appearance-none"
											value={(currentTime / duration) * 100 || 0}
											onChange={handleSeek}
										/>
									</div>
									<div className=" ">
										<div className="text-lg font-semibold">{getRemainingTime()}</div>
									</div>
								</div>
								{/* ---- */}

								<div className="flex items-center justify-between space-x-4">
									<span className="text-[12px] logo font-normal text-gray-400">Volume</span>
									<input
										type="range"
										className="w-full h-2 bg-gray-600 rounded-lg appearance-none"
										min="0"
										max="1"
										step="0.1"
										value={volume}
										onChange={handleVolumeChange}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
