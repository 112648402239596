// import packages
import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// import assets
import podcast from '../../../public/assets/podcast.png';
import bull from '../../../public/assets/bull.png';
import team from '../../../public/assets/team.png';
import reports from '../../../public/assets/reports.png';

export const Landing = () => {
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [success, setSuccess] = useState(null); // New state to track success status

	useEffect(() => {
		let timeout;
		if (message) {
			// Clear the message and success state after 5 seconds
			timeout = setTimeout(() => {
				setMessage('');
				setEmail('');
				setSuccess(null);
			}, 5000);
		}

		// Cleanup function to clear the timeout if the component unmounts
		return () => clearTimeout(timeout);
	}, [message]);

	const handleSubmit = async e => {
		e.preventDefault();

		try {
			// Send the email to the backend API
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/signup`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email }),
			});

			const data = await response.json();

			if (response.ok) {
				// Clear the email field and set success message
				setEmail('');
				setSuccess(true);
				setMessage(data.message || 'Thank you for signing up! Please check your email to verify your subscription.');
			} else {
				setSuccess(false);
				setMessage(data.message || 'Error during sign-up. Please try again.');
			}
		} catch (error) {
			setSuccess(false);
			setMessage('An error occurred. Please try again.');
			console.error(error);
		}
	};

	const renderSignupResponse = () => {
		if (message) {
			const containerVariants = {
				hidden: { opacity: 0, scale: 0.95 },
				visible: { opacity: 1, scale: 1 },
				exit: { opacity: 0, scale: 0.95 },
			};

			return (
				<AnimatePresence>
					<motion.div
						key="signup-response"
						className={`w-full px-4 py-4 rounded-lg drop-shadow-lg ${success ? 'bg-luif-cta-2' : 'bg-red-500'}`}
						variants={containerVariants}
						initial="hidden"
						animate="visible"
						exit="exit"
						transition={{
							type: 'spring',
							stiffness: 120,
							damping: 20,
						}}>
						<p className="text-zinc-900 maintext tracking-wide text-center font-semibold">{message}</p>
					</motion.div>
				</AnimatePresence>
			);
		}
	};

	return (
		<div className="text-luif-text my-0">
			{/* Header Section */}
			<div className="">
				<div className="bg-gray-800">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
						<div></div>
						<div className="sm:col-start-2 sm:col-span-4 py-12 space-y-4">
							<motion.h1
								initial={{ y: -50, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.2, duration: 0.8 }}
								className="text-[32px] text-luif-text logo uppercase font-black">
								LVLUPINFINANCE
							</motion.h1>

							<div className="my-4">
								<motion.img
									src={bull}
									alt="podcast section with a stock market chart in the background"
									className="rounded-xl"
									initial={{ opacity: 0, scale: 0.95 }}
									whileInView={{ opacity: 1, scale: 1 }}
									transition={{ duration: 0.8 }}
									loading="lazy"
								/>
							</div>

							<motion.p
								initial={{ y: 20, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.4, duration: 0.8 }}
								className="text-[20px] text-luif-text subText font-normal break-words">
								Finance shouldn’t be intimidating. At LVLUPINFINANCE, we make complex company reports simple, clear, and actionable. Our mission is to empower you
								with practical knowledge that gives you an edge in investing—no jargon, just straightforward insights.
							</motion.p>
						</div>
						<div></div>
					</div>
				</div>

				{/* <hr className="my-8" /> */}

				{/* Who We Are Section */}
				<div className="bg-slate-50">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
						<div></div>
						<div className="sm:col-start-2 sm:col-span-4 py-12 space-y-4 text-zinc-900">
							<motion.h1
								initial={{ y: -50, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.2, duration: 0.8 }}
								className="text-[32px] logo uppercase font-black">
								Who We Are
							</motion.h1>

							<div className="my-4">
								<motion.img
									src={team}
									alt="podcast section with a stock market chart in the background"
									className="rounded-xl"
									initial={{ opacity: 0, scale: 0.95 }}
									whileInView={{ opacity: 1, scale: 1 }}
									transition={{ duration: 0.8 }}
									loading="lazy"
								/>
							</div>

							<motion.p
								initial={{ y: 20, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.4, duration: 0.8 }}
								className="text-[20px] subText font-normal break-words">
								We’re a team of finance enthusiasts with a shared goal: to help people confidently interpret financial reports. From decoding earnings statements to
								breaking down regulatory filings, we offer in-depth resources that reveal what truly drives a company’s value. Whether you’re looking to sharpen
								your investing skills or gain a deeper understanding of how companies operate, we’re here to guide you.
							</motion.p>
						</div>
						<div></div>
					</div>
				</div>

				{/* <hr className="my-8" /> */}

				{/* The Problem We’re Solving Section */}
				<div className="bg-gray-800">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
						<div></div>
						<div className="sm:col-start-2 sm:col-span-4 py-12 space-y-4 ">
							<motion.h1
								initial={{ y: -50, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.2, duration: 0.8 }}
								className="text-[32px] text-luif-text logo uppercase font-black">
								The Problem We’re Solving
							</motion.h1>
							<motion.p
								initial={{ y: 20, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								transition={{ ease: 'easeOut', delay: 0.4, duration: 0.8 }}
								className="text-[20px] text-luif-text subText font-normal break-words">
								Many people want to make smart investments but struggle with the complexity of financial statements and regulatory filings. We bridge that gap by
								explaining companies’ SEC filings—like the 10-Q and 10-K—through educational content and insightful analysis. We believe that knowledge is the best
								investment, and we’re here to make it accessible to you.
							</motion.p>
						</div>

						<div></div>
					</div>
				</div>

				{/* <hr className="my-8" /> */}

				{/* Podcast Promotion Section */}
				<div className="">
					<div className="bg-slate-50">
						<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
							<div></div>
							<div className="sm:col-start-2 sm:col-span-4 py-12 text-zinc-900">
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{ ease: 'easeOut', delay: 0.4, duration: 1.3 }}
									className="bg-slate-200 px-8 py-4 rounded-lg drop-shadow-xl">
									<div className="my-4">
										<h1 className="text-[32px] logo uppercase font-black m-0 p-0">Try Our New Podcast for Free!</h1>
										<p className="text-[20px] mainText font-black m-0 p-0">Unlock a New Perspective on Company Reports</p>
									</div>

									<div className="my-4">
										<motion.img
											src={podcast}
											alt="podcast section with a stock market chart in the background"
											className="rounded-xl drop-shadow-xl"
											initial={{ opacity: 0, scale: 0.95 }}
											whileInView={{ opacity: 1, scale: 1 }}
											transition={{ duration: 0.8 }}
											loading="lazy"
										/>
									</div>

									<div className="my-4 space-y-2">
										<p className="text-[20px] subText font-normal tracking-normal break-words">
											As a special offer, enjoy our latest podcast episode, where we break down a company’s recent SEC filing in a lively question-and-answer
											format. It’s a sneak peek into our upcoming series of in-depth company breakdowns! Listen now to see how we can make complex financial
											information approachable, engaging, and valuable.
										</p>
										<p className="text-[20px] subText font-normal tracking-normal break-words">
											Simply sign up below to access this free episode, and join our community of learners who are leveling up their financial knowledge.
										</p>
									</div>
									{/* Sign-Up Section */}
									<div className="my-4">
										{/* Input with animation */}
										<motion.input
											type="email"
											placeholder="Enter your email to sign up"
											value={email}
											onChange={e => setEmail(e.target.value)}
											required
											className="px-8 py-4 pl-4 rounded-lg w-full text-zinc-800 border border-zinc-900"
											onKeyDown={e => {
												if (e.key === 'Enter') {
													handleLogin(); // Call handleLogin when Enter key is pressed
												}
											}}
											initial={{ scale: 1, boxShadow: '0px 0px 0px #1f2937' }}
											whileFocus={{
												scale: 1.05,
												boxShadow: '0px 0px 15px #d9f99d',
											}}
											transition={{
												type: 'spring',
												stiffness: 200,
												damping: 20,
											}}
										/>

										{/* Button with hover and tap animation */}
										<div className="flex justify-center">
											<motion.div
												whileHover={{ scale: 1.05 }}
												whileTap={{
													scale: 1.1,
													transition: { type: 'spring', stiffness: 300, damping: 15, delay: 0.05 },
												}}
												onClick={e => handleSubmit(e)}
												className="my-4 rounded-xl bg-luif-cta-2 text-luif-bg px-8 py-4 text-[16px] logo uppercase font-black text-center w-full cursor-pointer">
												Sign up
											</motion.div>
										</div>
										{renderSignupResponse()}
									</div>
								</motion.div>
							</div>
							<div></div>
						</div>
					</div>
				</div>

				{/* <hr className="my-8" /> */}

				{/* Financial Glossary Section */}
				<div className="bg-gray-800">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
						<div></div>
						<div className="sm:col-start-2 sm:col-span-4 py-12 space-y-4">
							<div className="space-y-4">
								<h1 className="text-[32px] text-luif-text logo uppercase font-black">Financial Glossary</h1>

								<div className="my-4">
									<motion.img
										src={reports}
										alt="podcast section with a stock market chart in the background"
										className="rounded-xl"
										initial={{ opacity: 0, scale: 0.95 }}
										whileInView={{ opacity: 1, scale: 1 }}
										transition={{ duration: 0.8 }}
										loading="lazy"
									/>
								</div>

								<p className="text-[20px] text-luif-text subText font-normal tracking-tight break-words">
									LVLUPINFINANCE offers a comprehensive Financial Glossary to help you navigate the world of finance with ease. Explore key terms, understand the
									ins and outs of SEC filings, and build a strong foundation for your financial journey.
								</p>
							</div>

							<div className="flex justify-center">
								<motion.div
									whileHover={{ scale: 1.05 }}
									whileTap={{
										scale: 1.1,
										transition: { type: 'spring', stiffness: 300, damping: 15, delay: 0.05 },
									}}
									onClick={() => window.location.assign('/glossary')}
									className="my-4 rounded-xl bg-luif-cta text-luif-bg px-8 py-4 text-[16px] logo uppercase font-black text-center w-full cursor-pointer">
									start exploring
								</motion.div>
							</div>
						</div>
					</div>
					<div></div>
				</div>

				{/* <hr className="my-8" /> */}

				{/* Support Section */}
				<div className="bg-slate-50">
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
						<div></div>
						<div className="sm:col-start-2 sm:col-span-4 py-12 space-y-4 text-zinc-900">
							<h1 className="text-[32px] logo uppercase font-black">Support Our Educational Mission</h1>
							<p className="text-[20px] mainText font-normal tracking-tight break-words">
								If you’ve found value in our educational content, consider supporting us. Your donations enable us to keep producing free, high-quality resources
								that make financial education accessible to all. Every contribution helps us continue this mission.
							</p>
							<div className="flex justify-center">
								<motion.div
									whileHover={{ scale: 1.05 }}
									whileTap={{
										scale: 1.1,
										transition: { type: 'spring', stiffness: 300, damping: 15, delay: 0.05 },
									}}
									onClick={() => window.location.assign('https://square.link/u/tFtalKxd')}
									className="my-4 rounded-xl bg-luif-cta-2 text-luif-bg px-8 py-4 text-[16px] logo uppercase font-black text-center w-full cursor-pointer">
									Support Us
								</motion.div>
							</div>
						</div>
						<div></div>
					</div>
				</div>
			</div>
		</div>
	);
};
