// import media
import oklo_podcast from 'url:../../public/assets/oklo_podcast.mp3';
import satellite from '../../public/assets/satellite.png';
import oklo_energy from '../../public/assets/future_clean_energy.png';
import ast_podcast from 'url:../../public/assets/podcast_2.mp3';

export const episodes = [
	{
		id: 1,
		title: 'Oklo’s 10-Q Filing Breakdown',
		description: 'Breaking down Oklo’s latest 10-Q SEC filing.',
		takeaways: ['Revenue increased by 20% YoY.', 'New reactor project timeline updates.', 'Risks outlined in the 10-Q filing.'],
		insights: 'Monitor upcoming regulatory filings for approval status.',
		audioSrc: oklo_podcast,
		imageSrc: oklo_energy,
		link: '#',
	},
	{
		id: 2,
		title: `Exploring AST SpaceMobile's Latest 10-Q`,
		description: `Explore insights from AST SpaceMobile's latest 10-Q filing.`,
		takeaways: [
			'Global smartphone connectivity via space-based broadband.',
			'$189M cash, heavy R&D, potential fundraising.',
			'Technical challenges, regulations, SpaceX, OneWeb competition.',
		],
		insights: 'Global connectivity hinges on overcoming technical, financial, and competitive challenges.',
		audioSrc: ast_podcast,
		imageSrc: satellite,
		link: '#',
	},
	// Add more episodes as needed
];
