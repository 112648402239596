// import packages
import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'motion/react';

// import data
import { episodes } from '../data/episodes.js';

// import components
import { Navbar } from '../components/navbar/index.jsx';
import { Footer } from '../components/footer/index.jsx';
import { AudioPlayer } from '../components/AudioPlayer/index.jsx';
import { useUserValidation } from '../hooks/userValidation.jsx';
import { StockChartLoading } from '../components/stock_chart_loading/index.jsx';

export const ReportsPage = () => {
	const { isVerified } = useUserValidation();
	// Framer Motion animations
	const fadeIn = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeInOut' } },
	};

	const staggerContainer = {
		hidden: { opacity: 1 },
		visible: { transition: { staggerChildren: 0.2 } },
	};

	if (isVerified === null) return <StockChartLoading />; // Show loading state

	return (
		<div className="bg-gray-100">
			{/* Navbar */}
			<Navbar />
			<Helmet>
				<title>Financial Reports | lvlupinfinance</title>
				<meta name="description" content="Explore in-depth breakdowns of companies' SEC filings and actionable insights to boost your financial knowledge." />
				<meta name="keywords" content="finance, SEC filings, investing, podcast, financial insights" />
			</Helmet>

			{/* Header Section */}
			<header className="text-center bg-gray-900 py-24 text-white">
				<motion.div className="max-w-4xl mx-auto" initial="hidden" animate="visible" variants={fadeIn}>
					<div className="mx-auto px-16">
						<h1 className="text-4xl sm:text-6xl font-extrabold logo mb-6">Exclusive Reports Hub</h1>
						<p className="text-lg sm:text-xl text-gray-400">
							Welcome, early access subscriber. Explore our growing library of in-depth podcast episodes breaking down SEC filings.
						</p>
					</div>
				</motion.div>
			</header>

			{/* Episodes Section */}
			<div className="py-4">
				<div className="max-w-6xl mx-auto px-4 sm:px-8 space-y-8">
					{episodes.map((episode, index) => (
						<div className="bg-zinc-200 sm:px-4 sm:py-4 px-4 py-4 rounded-xl drop-shadow-xl" key={episode.id}>
							<motion.div
								className="relative grid grid-cols-1 sm:grid-cols-6 sm:space-x-4 space-y-4 sm:space-y-0"
								initial="hidden"
								whileInView="visible"
								variants={{
									hidden: { opacity: 0, scale: 0.95 },
									visible: { opacity: 1, scale: 1, transition: { delay: index * 0.2, duration: 0.5 } },
								}}
								viewport={{ once: true }}>
								{/* Content Box */}
								{/* <div> */}

								<div className="col-span-3 bg-white rounded-xl drop-shadow-xl p-6">
									<h2 className="text-2xl font-bold mb-4">{episode.title}</h2>
									<p className="text-gray-600 mb-6">{episode.description}</p>
									<h3 className="text-lg font-semibold mb-2">Key Takeaways:</h3>
									<ul className="list-disc list-inside text-gray-700 mb-4">
										{episode.takeaways.map((takeaway, idx) => (
											<li key={idx}>{takeaway}</li>
										))}
									</ul>
									<div className="text-gray-700">
										<strong>Insights:</strong> {episode.insights}
									</div>
								</div>

								{/* Audio Player */}
								<div className="col-span-3 flex justify-center items-center">
									<div className="bg-gradient-to-r from-blue-600 to-purple-600 rounded-xl drop=shadow-xl px-2 py-2 sm:p-4">
										<AudioPlayer src={episode.audioSrc} title={episode.title} image={episode.imageSrc} />
									</div>
								</div>
								{/* </div> */}
							</motion.div>
						</div>
					))}
				</div>
			</div>

			{/* Footer */}
			<Footer />
		</div>
	);
};
