// import packages
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const useUserValidation = () => {
	const [isVerified, setIsVerified] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();

	const storeToken = token => {
		localStorage.setItem('userToken', token);
	};

	const removeToken = () => {
		localStorage.removeItem('userToken');
	};

	const isTokenExpired = token => {
		try {
			const payload = JSON.parse(atob(token.split('.')[1]));
			const currentTime = Math.floor(Date.now() / 1000);
			return payload.exp < currentTime;
		} catch (error) {
			console.error('Error checking token expiration:', error);
			return true;
		}
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const token = queryParams.get('token');
		if (token) {
			storeToken(token);
			navigate(location.pathname, { replace: true });
		}
	}, [location.search, navigate]);

	useEffect(() => {
		const verifyAccess = async () => {
			const token = localStorage.getItem('userToken');
			if (!token || isTokenExpired(token)) {
				removeToken();
				navigate('/not-authorized', { replace: true });
				return;
			}

			try {
				const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/check-verification`, {
					method: 'POST',
					headers: { Authorization: `Bearer ${token}` },
				});

				if (response.ok) {
					setIsVerified(true);
				} else {
					removeToken();
					navigate('/not-authorized', { replace: true });
				}
			} catch (error) {
				console.error('Verification failed:', error);
				removeToken();
				navigate('/not-authorized', { replace: true });
			}
		};

		verifyAccess();
	}, [navigate]);

	return { isVerified };
};
