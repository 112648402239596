// Import packages
import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion'; // Update to `framer-motion`

// Audio Player Component
export const AudioPlayer = ({ src, title, image }) => {
	const [audio] = useState(new Audio(src)); // Create the audio instance
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [volume, setVolume] = useState(1);

	// Set up audio event listeners
	useEffect(() => {
		const handleLoadedMetadata = () => setDuration(audio.duration);
		const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
		const handleAudioEnd = () => setIsPlaying(false);

		audio.addEventListener('loadedmetadata', handleLoadedMetadata);
		audio.addEventListener('timeupdate', handleTimeUpdate);
		audio.addEventListener('ended', handleAudioEnd);

		return () => {
			audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
			audio.removeEventListener('timeupdate', handleTimeUpdate);
			audio.removeEventListener('ended', handleAudioEnd);
		};
	}, [audio]);

	// Play/Pause toggle
	const togglePlayPause = () => {
		if (isPlaying) {
			audio.pause();
		} else {
			audio.play();
		}
		setTimeout(() => setIsPlaying(!isPlaying), 150);
	};

	// Seek functionality
	const handleSeek = e => {
		const newTime = (e.target.value / 100) * duration;
		audio.currentTime = newTime;
		setCurrentTime(newTime);
	};

	// Volume control
	const handleVolumeChange = e => {
		const newVolume = e.target.value;
		audio.volume = newVolume;
		setVolume(newVolume);
	};

	// Format time to MM:SS
	const formatTime = time => {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);
		return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	};

	// Calculate remaining time
	const getRemainingTime = () => formatTime(duration - currentTime);

	// Clean up audio on unmount
	useEffect(() => {
		return () => {
			audio.pause();
			audio.currentTime = 0;
		};
	}, [audio]);

	return (
		<div className="audio-player bg-zinc-800 rounded-xl drop-shadow-xl">
			{/* Optional Image */}
			{image && (
				<div className="image-container">
					<div className="max-h-24 sm:max-h-36 overflow-clip rounded-t-xl">
						<img src={image} alt={title} className="w-full rounded-t-xl relative bottom-20 sm:bottom-40 left-0" />
					</div>
				</div>
			)}
			<div className="controls p-4 text-white">
				{/* Title */}
				{title && <h2 className="text-[16px] font-semibold">{title}</h2>}
				<p className="text-zinc-400 text-[16px]">LVLUPINFINANCE</p>

				{/* Play/Pause and Seek */}
				<div className="grid grid-cols-8 items-center gap-4 mt-4">
					<button onClick={togglePlayPause} className="text-3xl">
						<AnimatePresence mode="wait">
							{isPlaying ? (
								<motion.svg
									key="pause-icon"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="currentColor"
									initial={{ scale: 0.8, opacity: 0 }}
									animate={{ scale: 1, opacity: 1 }}
									exit={{ scale: 0.8, opacity: 0 }}
									transition={{ duration: 0.2 }}>
									<rect x="6" y="4" width="4" height="16"></rect>
									<rect x="14" y="4" width="4" height="16"></rect>
								</motion.svg>
							) : (
								<motion.svg
									key="play-icon"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="currentColor"
									initial={{ scale: 0.8, opacity: 0 }}
									animate={{ scale: 1, opacity: 1 }}
									exit={{ scale: 0.8, opacity: 0 }}
									transition={{ duration: 0.2 }}>
									<polygon points="5,3 19,12 5,21"></polygon>
								</motion.svg>
							)}
						</AnimatePresence>
					</button>
					<input type="range" className="col-span-6 w-full h-2 bg-gray-600 rounded-lg" value={(currentTime / duration) * 100 || 0} onChange={handleSeek} />
					<span className="text-sm">{getRemainingTime()}</span>
				</div>

				{/* Volume */}
				{/* <div className="flex items-center gap-2 mt-4">
					<span className="text-sm text-gray-400">Volume</span>
					<input type="range" className="w-full h-2 bg-gray-600 rounded-lg" min="0" max="1" step="0.1" value={volume} onChange={handleVolumeChange} />
				</div> */}
			</div>
		</div>
	);
};
