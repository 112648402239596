// import packages
import { useState } from 'react';
import { motion } from 'framer-motion';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { FaFileAlt, FaExclamationTriangle, FaChartBar, FaCoins, FaBook, FaMoneyCheck, FaIndustry, FaBolt, FaBuilding } from 'react-icons/fa';

// import components
import { Navbar } from '../navbar/index.jsx';
import { Footer } from '../footer/index.jsx';
import { FormS1Quiz } from '../quizzes/form_s1.jsx';

export const FormS1 = () => {
	const [openIndex, setOpenIndex] = useState(null);

	const fadeIn = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
	};

	const faqs = [
		{ question: 'What is Form S-1?', answer: 'Form S-1 is the SEC filing companies must submit before they sell shares to the public.' },
		{ question: 'Is Form S-1 only for IPOs?', answer: 'Nope! Companies also file a new S-1 if they want to raise additional funds after already being public.' },
		{ question: 'Why should I care about Form S-1?', answer: 'It’s your window into the company’s financial health, growth strategy, and risks.' },
		{ question: 'What should I focus on in the Form S-1?', answer: "Sections like 'Use of Proceeds,' 'Risk Factors,' and 'Business Overview.' " },
		{ question: 'How do I know if a company is being transparent?', answer: "Vague language in sections like 'Use of Proceeds' is a red flag." },
	];

	const sectionData = [
		{
			icon: <FaBook className="text-indigo-500 text-xl" />,
			title: 'General Corporate Purposes',
			say: 'We plan to use the funds for general corporate purposes.',
			means: 'This could mean anything, from paying down debt to funding expansion. It’s purposely vague, so always check if they give more details elsewhere in the form.',
			decode: 'Look for clues in sections like \u201cBusiness Overview\u201d or \u201cManagement\u2019s Discussion.\u201d If the company isn’t specific, it might indicate they don’t have a clear strategy or are hesitant to reveal too much.',
		},
		{
			icon: <FaMoneyCheck className="text-green-500 text-xl" />,
			title: 'Working Capital',
			say: 'A portion of the proceeds will be used for working capital.',
			means: 'This typically means the company needs cash to cover day-to-day expenses like payroll, inventory, and rent.',
			decode: 'If a large portion of proceeds is going toward working capital, it could mean the company is struggling to maintain its operations. Compare this with their current cash reserves to get a better sense of their financial health.',
		},
		{
			icon: <FaIndustry className="text-yellow-500 text-xl" />,
			title: 'Strategic Investments',
			say: 'We plan to make strategic investments with the proceeds.',
			means: 'They want to grow, whether through acquiring new technology, expanding into new markets, or hiring more staff.',
			decode: 'Check if these investments align with their core business. If they’re investing in areas they’re unfamiliar with, that could be risky.',
		},
		{
			icon: <FaBolt className="text-red-500 text-xl" />,
			title: 'Debt Repayment',
			say: 'Proceeds will be used to repay existing indebtedness.',
			means: 'They’re using the money to pay off debt.',
			decode: 'While paying off debt is often a smart move, if too much of the proceeds are going toward this, it could indicate the company is over-leveraged. Look for the interest rates on that debt to assess whether this is a positive step or a warning sign.',
		},
		{
			icon: <FaBuilding className="text-blue-500 text-xl" />,
			title: 'Acquisitions',
			say: 'We may use a portion of the proceeds for potential acquisitions.',
			means: 'They plan to buy other companies or assets to help them grow.',
			decode: 'Ask yourself—does the company have a good track record with acquisitions? Mergers and acquisitions (M&A) can boost growth, but they can also be risky if not executed well.',
		},
	];

	const toggleFAQ = index => {
		setOpenIndex(index === openIndex ? null : index);
	};

	return (
		<>
			<Navbar />
			<div className="bg-slate-50">
				<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-4 py-12 text-zinc-900">
						<h1 className="text-[32px] logo uppercase font-black">Form S-1</h1>
						<p className="text-[16px] subText font-normal tracking-tight break-words">Last updated: December 2024</p>
					</div>
					<div></div>
				</div>
			</div>

			<div className="text-zinc-50">
				<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-4 py-12">
						<h1 className="text-[32px] logo uppercase font-black">Introduction to Form S-1</h1>
						<p className="subText text-[16px] tracking-normal font-light">
							So, you’ve probably heard about companies “going public” or raising funds by issuing new stock, but what’s really going on behind the scenes? Enter
							<span className="font-bold"> Form S-1</span>—the document every company needs to file with the SEC (Securities and Exchange Commission) before they can
							sell shares to the public. Whether it’s for an initial public offering (IPO) or simply raising more capital after being public, this form is where the
							company tells its story, discloses key financials, and lets you in on their plans for all the cash they’re hoping to raise.
						</p>
						<p className="subText text-[16px] tracking-normal font-light">
							Reading a Form S-1 can feel a bit like reading a manual for a car you’ve never driven—it’s packed with details, but once you understand the language, it
							starts to make sense. In this guide, I’ll help you decode this form, especially the “Use of Proceeds” section, where companies reveal what they plan to
							do with the money they’re raising. Ready to dive in?
						</p>
					</div>
					<div></div>
				</div>
			</div>

			<div className="bg-slate-50">
				<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-4 py-12">
						<motion.h1 className="text-2xl font-bold text-gray-800" initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
							FAQs About Form S-1
						</motion.h1>
						<motion.p className="text-lg text-gray-600" initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.7 }}>
							Before we go deeper, let’s quickly answer some common questions about the form to set the stage.
						</motion.p>
						{faqs.map((faq, index) => (
							<div key={index} className="border-b border-gray-200 py-2">
								<button
									className="w-full flex justify-between items-center text-left text-gray-800 font-medium focus:outline-none"
									onClick={() => toggleFAQ(index)}>
									<span>{faq.question}</span>
									{openIndex === index ? <FiChevronUp /> : <FiChevronDown />}
								</button>
								{openIndex === index && (
									<motion.p
										className="mt-2 text-gray-600 text-sm"
										initial={{ opacity: 0, height: 0 }}
										animate={{ opacity: 1, height: 'auto' }}
										exit={{ opacity: 0, height: 0 }}>
										{faq.answer}
									</motion.p>
								)}
							</div>
						))}
					</div>
					<div></div>
				</div>
			</div>

			<div className="text-slate-50">
				<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-4 py-12">
						<div className="space-y-8">
							<motion.p
								className="text-[24px] tracking-tight font-black"
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.5 }}>
								Important Sections in Form S-1
							</motion.p>

							<motion.p
								className="text-[20px] tracking-tight font-semibold my-2"
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.7 }}>
								Now that you’re more familiar with what Form S-1 is and why it matters, let’s look at some of the key sections you’ll encounter:
							</motion.p>

							<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
								<motion.div
									className="flex items-center space-x-4 p-6 bg-slate-800 rounded-lg shadow-md"
									initial={{ opacity: 0, x: -50 }}
									whileInView={{ opacity: 1, x: 0 }}
									transition={{ duration: 0.5 }}>
									<FaFileAlt className="text-blue-400 text-3xl" />
									<div>
										<p className="font-bold underline">Business Overview</p>
										<p className="text-sm text-gray-300">The company outlines what they do, their market, and their business model.</p>
									</div>
								</motion.div>

								<motion.div
									className="flex items-center space-x-4 p-6 bg-slate-800 rounded-lg shadow-md"
									initial={{ opacity: 0, x: 50 }}
									whileInView={{ opacity: 1, x: 0 }}
									transition={{ duration: 0.5 }}>
									<FaExclamationTriangle className="text-yellow-400 text-3xl" />
									<div>
										<p className="font-bold underline">Risk Factors</p>
										<p className="text-sm text-gray-300">Lists potential risks—valuable insights often hidden here.</p>
									</div>
								</motion.div>

								<motion.div
									className="flex items-center space-x-4 p-6 bg-slate-800 rounded-lg shadow-md"
									initial={{ opacity: 0, x: -50 }}
									whileInView={{ opacity: 1, x: 0 }}
									transition={{ duration: 0.5 }}>
									<FaChartBar className="text-green-400 text-3xl" />
									<div>
										<p className="font-bold underline">Financial Statements</p>
										<p className="text-sm text-gray-300">Includes income statement, balance sheet, and cash flow.</p>
									</div>
								</motion.div>

								<motion.div
									className="flex items-center space-x-4 p-6 bg-slate-800 rounded-lg shadow-md"
									initial={{ opacity: 0, x: 50 }}
									whileInView={{ opacity: 1, x: 0 }}
									transition={{ duration: 0.5 }}>
									<FaCoins className="text-purple-400 text-3xl" />
									<div>
										<p className="font-bold underline">Use of Proceeds</p>
										<p className="text-sm text-gray-300">Explains how the company plans to use raised funds.</p>
									</div>
								</motion.div>
							</div>
						</div>
					</div>
					<div></div>
				</div>
			</div>

			<div className="bg-zinc-50 py-12">
				<div className="grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-4">
						<motion.div initial="hidden" animate="visible" variants={fadeIn} className="space-y-6 text-left">
							<h1 className="text-2xl font-black text-gray-800 tracking-tight">Decoding the "Use of Proceeds" Section</h1>
							<p className="text-lg text-gray-600 font-medium leading-relaxed">
								Here’s where the real detective work begins. The “Use of Proceeds” section tells you how the company plans to spend the money raised from selling
								its stock. But companies often use vague language, so it's crucial to read between the lines to see what they're really saying.
							</p>

							<div className="space-y-8">
								{sectionData.map((item, index) => (
									<motion.div
										key={index}
										initial="hidden"
										whileInView="visible"
										viewport={{ once: true, amount: 0.2 }}
										variants={fadeIn}
										className="bg-white shadow-md rounded-lg p-6 space-y-4 border-t-4 border-indigo-500">
										<div className="flex items-center space-x-3">
											{item.icon}
											<h2 className="text-xl font-bold text-gray-800">{item.title}</h2>
										</div>
										<p className="text-gray-700 font-semibold">
											<span className="underline font-bold">What They Say:</span> {item.say}
										</p>
										<p className="text-gray-700">
											<span className="underline font-bold">What It Means:</span> {item.means}
										</p>
										<p className="text-gray-700">
											<span className="underline font-bold">How to Decode It:</span> {item.decode}
										</p>
									</motion.div>
								))}
							</div>
						</motion.div>
					</div>
					<div></div>
				</div>
			</div>

			<div className="bg-gray-800 text-zinc-50">
				<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-4 py-12">
						<div className="space-y-8">
							<p className="mainText text-[24px] tracking-tight font-black">Support Our Mission</p>
							<p className="subText text-[20px] tracking-normal font-light">
								If you’ve found this breakdown helpful, supporting content like this allows us to continue creating easy-to-understand guides for important
								financial topics. By donating, you’re helping us provide more tools and resources for people just like you who want to make informed decisions in
								the world of investing. Every bit counts, so if you’re able, please consider contributing to support our mission.
							</p>
							<div className="">
								<a
									href="https://square.link/u/tFtalKxd"
									className="rounded-xl bg-luif-cta-2 text-luif-bg px-8 py-4 text-[16px] mainText font-bold hover:tracking-widest">
									Support Us!
								</a>
							</div>
						</div>
					</div>
					<div></div>
				</div>
			</div>

			<Footer />
		</>
	);
};
