// import packahes
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// import components
import { App } from './app.js';
import { Form4 } from './components/glossary/form_4.jsx';
import { FormS1 } from './components/glossary/form_s1.jsx';

// import pages
import { Tos } from './pages/tos.jsx';
import { Privacy } from './pages/pp.jsx';
import { Glossary } from './pages/glossary.jsx';
import { PodcastPage } from './pages/podcast/index.jsx';
import { NotAuthorizedPage } from './pages/not_authorized.jsx';
import { LoginPage } from './pages/login.jsx';
import { ReportsPage } from './pages/reports_page.jsx';

const container = document.getElementById('app');
const root = createRoot(container);

// inject react router
const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
	},
	{
		path: '/terms-of-service',
		element: <Tos />,
	},
	{
		path: '/privacy-policy',
		element: <Privacy />,
	},
	{
		path: '/glossary',
		element: <Glossary />,
	},
	{
		path: '/glossary/form-4',
		element: <Form4 />,
	},
	{
		path: '/glossary/form-s-1',
		element: <FormS1 />,
	},
	{
		path: '/podcast',
		element: <PodcastPage />,
	},
	{
		path: '/not-authorized',
		element: <NotAuthorizedPage />,
	},
	{
		path: '/reports',
		element: <ReportsPage />,
	},
	{
		path: '/login',
		element: <LoginPage />,
	},
	{
		path: '*',
		element: <App />,
	},
]);

root.render(
	<div className="bg-gray-800 h-dvh w-dvw overflow-scroll">
		<RouterProvider router={router} />
	</div>
);
