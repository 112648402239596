// import packages
import { useState } from 'react';
import { motion } from 'motion/react';

// import components
import { Navbar } from '../components/navbar/index.jsx';
import { Footer } from '../components/footer/index.jsx';

// Updated LoginPage with improved animations
export const LoginPage = () => {
	const [email, setEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const handleLogin = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email }),
			});

			const data = await response.json();
			if (response.ok) {
				localStorage.setItem('userToken', data.token);
				window.location.replace('/podcast');
			} else {
				setErrorMessage(data.message || 'Login failed. Please try again.');
			}
		} catch (error) {
			console.error(error);
			setErrorMessage('An error occurred during login. Please try again later.');
		}
	};

	const renderErrorResponse = () => {
		if (errorMessage) {
			return (
				<motion.div
					className="w-full px-4 py-4 rounded-lg drop-shadow-lg bg-red-500"
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 10 }}
					transition={{ duration: 0.5, ease: 'easeOut' }}>
					<p className="text-zinc-900 maintext tracking-wide text-center font-semibold">{errorMessage}</p>
				</motion.div>
			);
		}
	};

	return (
		<div>
			<Navbar />
			<div className="bg-gray-800">
				<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32 my-4">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-4 py-12 space-y-2">
						<motion.div
							initial={{ y: 50, opacity: 0 }}
							animate={{ y: 0, opacity: 1 }}
							transition={{ type: 'spring', stiffness: 100, damping: 20 }}
							className="bg-slate-200 py-8 px-8 text-zinc-900 rounded-xl drop-shadow-xl space-y-4">
							<motion.p
								initial={{ opacity: 0, scale: 0.9 }}
								animate={{ opacity: 1, scale: 1 }}
								transition={{ delay: 0.3, duration: 1 }}
								className="uppercase logo font-black text-[28px] text-center">
								Welcome back!
							</motion.p>

							<div>
								<p className="logo uppercase font-black text-[12px]">Email</p>
								<motion.input
									type="email"
									placeholder="example@example.com"
									value={email}
									onChange={e => setEmail(e.target.value)}
									required
									className="px-4 py-2 pl-4 rounded-lg w-full text-zinc-800"
									onKeyDown={e => {
										if (e.key === 'Enter') {
											handleLogin();
										}
									}}
									initial={{ scale: 1 }}
									whileFocus={{
										scale: 1.05,
										boxShadow: '0px 0px 15px #d9f99d',
									}}
									transition={{
										type: 'spring',
										stiffness: 200,
										damping: 15,
									}}
								/>
							</div>

							{renderErrorResponse()}

							<motion.div
								whileHover={{ scale: 1.1 }}
								whileTap={{
									scale: 0.95,
									transition: { type: 'spring', stiffness: 300, damping: 15 },
								}}
								onClick={handleLogin}
								className="rounded-xl bg-luif-cta-2 text-luif-bg px-4 py-2 text-[16px] logo uppercase font-semibold text-center w-full cursor-pointer">
								Login
							</motion.div>

							<motion.div
								whileHover={{ scale: 1.05 }}
								whileTap={{
									scale: 0.95,
									transition: { type: 'spring', stiffness: 300, damping: 15 },
								}}
								onClick={() => window.location.assign('/')}
								className="rounded-xl bg-gray-800 text-zinc-50 border border-zinc-50 px-4 py-2 text-[16px] logo uppercase font-semibold text-center w-full cursor-pointer">
								Signup
							</motion.div>
						</motion.div>
					</div>
					<div></div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
