// import packages
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaFileAlt, FaChartLine, FaLightbulb } from 'react-icons/fa';

// import component
import { Navbar } from '../components/navbar/index.jsx';
import { Footer } from '../components/footer/index.jsx';

// import icons
import { Arrow } from '../../public/assets/chev/index.jsx';

export const Glossary = () => {
	const navigate = useNavigate();

	return (
		<>
			<Navbar />
			<div className="">
				{/* header section */}
				<motion.div
					className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-2 sm:px-32"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 1 }}>
					<div></div>
					<div className="text-luif-text my-4 col-span-4 py-12 sm:px-8 px-8 drop-shadow-xl">
						<div className="space-y-2">
							<p className="text-[32px] logo font-black tracking-normal">The Ultimate Hub for SEC Filings and Financial Form Breakdowns</p>
							<p className="text-[20px] subText font-normal tracking-tight">
								Welcome to your go-to resource for understanding SEC filings and essential financial forms. Our detailed breakdowns of documents like Form 4 and
								Form S-1 demystify insider trading, IPO registrations, and much more.
							</p>
							<p className="text-[20px] subText font-normal tracking-tight">
								Whether you're an investor, financial analyst, or curious learner, our glossary empowers you to make smarter financial decisions. Plus, members get
								exclusive access to our podcast for in-depth discussions on financial topics.
							</p>
						</div>
					</div>
					<div></div>
				</motion.div>

				{/* Info section with animation */}
				<motion.div className="bg-slate-50 py-12 px-6 sm:px-12" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
					<h2 className="text-2xl sm:text-3xl font-bold text-gray-800 text-center mb-6">Not sure what all these forms mean? We've got your back.</h2>
					<p className="text-center text-gray-600 mb-12">
						Dive into simplified explanations of SEC filings and see how they affect your investments. Knowledge is profit!
					</p>
					<div className="grid gap-6 sm:gap-8 grid-cols-1 sm:grid-cols-3">
						<div className="bg-white shadow-md p-6 rounded-lg hover:shadow-lg transition-shadow duration-300 text-center">
							<FaFileAlt className="text-luif-cta-2 text-4xl mb-4 mx-auto" />
							<h3 className="text-lg font-semibold text-gray-800">Form 4</h3>
							<p className="text-gray-600 text-sm">Insider Trading Reports</p>
						</div>
						<div className="bg-white shadow-md p-6 rounded-lg hover:shadow-lg transition-shadow duration-300 text-center">
							<FaChartLine className="text-luif-cta-2 text-4xl mb-4 mx-auto" />
							<h3 className="text-lg font-semibold text-gray-800">Form S-1</h3>
							<p className="text-gray-600 text-sm">IPO Registration Details</p>
						</div>
						<div className="bg-white shadow-md p-6 rounded-lg hover:shadow-lg transition-shadow duration-300 text-center">
							<FaLightbulb className="text-luif-cta-2 text-4xl mb-4 mx-auto" />
							<h3 className="text-lg font-semibold text-gray-800">More Coming Soon</h3>
							<p className="text-gray-600 text-sm">Stay tuned for more breakdowns!</p>
						</div>
					</div>
				</motion.div>

				{/* Glimpse section with fade-in */}
				<motion.div
					className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-2 sm:px-32"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 1, delay: 0.5 }}>
					<div></div>
					<div className="text-slate-50 my-4 col-span-4 py-12 sm:px-8 px-8 drop-shadow-xl">
						<p className="text-[20px] font-normal tracking-tight">
							Dive into our glossary for straightforward explanations of financial forms and terms. Equip yourself with the knowledge to confidently navigate the
							financial landscape and take charge of your investments today.
						</p>
					</div>
					<div></div>
				</motion.div>

				{/* Cards with hover effects and animations */}
				<motion.div className="bg-slate-50" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 1 }}>
					<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-2 sm:px-32">
						<div></div>
						<div className="my-4 col-span-4 py-12 sm:px-8 px-8">
							<div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
								<motion.div
									className="bg-white py-6 px-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border border-gray-200 cursor-pointer"
									onClick={() => navigate('/glossary/form-4', { replace: false })}
									whileHover={{ scale: 1.05 }}
									transition={{ duration: 0.3 }}>
									<div className="flex items-center justify-between">
										<div>
											<p className="font-bold text-lg text-gray-800">Form 4</p>
											<p className="text-sm text-gray-600">Insider Trading Reports</p>
										</div>
										<div className="h-10 w-10 bg-gray-100 flex items-center justify-center rounded-full">
											<Arrow className="h-6 w-6 text-gray-600" />
										</div>
									</div>
								</motion.div>
								<motion.div
									className="bg-white py-6 px-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border border-gray-200 cursor-pointer"
									onClick={() => navigate('/glossary/form-s-1', { replace: false })}
									whileHover={{ scale: 1.05 }}
									transition={{ duration: 0.3 }}>
									<div className="flex items-center justify-between">
										<div>
											<p className="font-bold text-lg text-gray-800">Form S-1</p>
											<p className="text-sm text-gray-600">IPO Registration Details</p>
										</div>
										<div className="h-10 w-10 bg-gray-100 flex items-center justify-center rounded-full">
											<Arrow className="h-6 w-6 text-gray-600" />
										</div>
									</div>
								</motion.div>
							</div>
						</div>
						<div></div>
					</div>
				</motion.div>
			</div>
			<div></div>
			<Footer />
		</>
	);
};
